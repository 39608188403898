import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { UserNotificationSvc } from 'src/app/common/services/UserNotificationSvc';
import { EsignatureEnvelopeStatus } from './esignature-envelope-status';
import { NgForm } from '@angular/forms';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService } from 'primeng/api';
import { DeleteEnvelopeModalService } from './delete-envelope-modal.service';
import { EsignatureEnvelopeService } from './esignature-envelope.service';
import { Router } from '@angular/router';
import { PopupMessageModalService } from './popup-message-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EsignatureRecipientStatus } from './esignature-recipient-status';
import { CurrentUserService, Dealer } from 'dfs-common-ngx';

@Component({
  selector: 'fin-esignature-status',
  templateUrl: './esignature-status.component.html',
  styleUrls: ['../../PortalMain.scss'],
  providers: [ConfirmationService],
})
export class EsignatureStatusComponent {
  public modalRef!: BsModalRef;
  currentUserPhone!: string;
  isSubmitting: boolean = false;
  tempRecipientList: string[] = [];
  okButtonCaption: string = 'Yes';
  cancelButtonCaption: string = 'No';
  emailEdited: string = '';

  get recipientPhoneList(): string[] {
    let result: string[] = [];
    this.esignatureEnvelope.esignatureRecipientStatuses.forEach((element) => {
      result.push(element.phone);
    });
    return result;
  }

  constructor(
    @Inject(DIALOG_DATA) public esignatureEnvelope: EsignatureEnvelopeStatus,
    private userNotificationSvc: UserNotificationSvc,
    private deleteEnvelopeModalService: DeleteEnvelopeModalService,
    private popupMessageModalService: PopupMessageModalService,
    private dialogRef: DialogRef,
    private esignatureEnvelopeService: EsignatureEnvelopeService,
    private router: Router,
    private readonly currentUserSvc: CurrentUserService
  ) {}

  ngOnInit() {
    const dealer = this.currentUserSvc.profile as Dealer;
    this.currentUserPhone = dealer.phone.replace(/[-+()\s]/g, '');
    this.reloadRecipientList();
  }
  delete() {
    this.deleteEnvelopeModalService
      .display(
        'Confirm Void of Envelope',
        'You are about to cancel the e-signature process for this application.<br /> This void cannot be reversed. <br><b>Are you sure you want to continue?</b>'
      )
      .subscribe({
        next: (response) => {
          if (response === 'yes') {
            this.deleteEnvelope(false);
          }
        },
      });
  }
  resendEnvelope(esigRecipientForm: NgForm) {
    if (esigRecipientForm.invalid) {
      return;
    }
    const title = 'Resend envelope';
    const message =
      'Please confirm that you want to resend the envelope.<br /> Only signers who have yet to sign will receive this email.';
    const okButtonCaption = 'Send';
    const cancelButtonCaption = 'Cancel';
    this.modalRef = this.popupMessageModalService.show(
      title,
      message,
      okButtonCaption,
      cancelButtonCaption
    );
    this.modalRef.content.onClose.subscribe((response: string) => {
      if (response === okButtonCaption) {
        this.isSubmitting = true;
        this.esignatureEnvelopeService
          .resendEnvelope(
            this.esignatureEnvelope.applicationId,
            this.esignatureEnvelope
          )
          .subscribe({
            next: () => {
              this.userNotificationSvc.success(
                'Envelope was succesfully resent.',
                'Success',
                10000,
                this.userNotificationSvc.topRight
              );
            },
            error: (error) => {
              this.userNotificationSvc.error(
                'There was an issue re sending the envelope!',
                'Error',
                10000,
                this.userNotificationSvc.topRight
              );
            },
            complete: () => {
              this.isSubmitting = false;
              this.close();
            },
          });
      }
    });
  }

  regenerateEnvelope() {
    this.deleteEnvelopeModalService
      .display(
        'Confirm Void and Re-Generate Envelope',
        'You are about to cancel the e-signature process for this application.<br /> The envelope will be voided and you will be routed to the Generate Documents screen. <br><b>Are you sure you want to continue?</b>'
      )
      .subscribe({
        next: (response) => {
          if (response === 'yes') {
            this.deleteEnvelope(true);
          }
        },
      });
  }
  reloadRecipientList() {
    this.tempRecipientList = this.recipientPhoneList;
  }
  askToUpdatePhone(esigRecipientForm: NgForm, i: number) {
    this.reloadRecipientList();
    if (esigRecipientForm.invalid) {
      return;
    }

    const title: string = "Update Customer's Mobile Phone #";
    const message: string =
      "Do you want to update this customer's account with this phone number?";
    const modalRef: BsModalRef = this.popupMessageModalService.show(
      title,
      message,
      this.okButtonCaption,
      this.cancelButtonCaption
    );
    modalRef.content.onClose.subscribe({
      next: (response: string) => {
        if (response === this.okButtonCaption) {
          this.esignatureEnvelope.esignatureRecipientStatuses[
            i
          ].updatePhoneInRapport = true;
        }
      },
    });
  }

  setDefaultEmail(i: number) {
    this.emailEdited =
      this.esignatureEnvelope.esignatureRecipientStatuses[i].email;
  }

  askToUpdateEmail(esigRecipientForm: NgForm, i: number) {
    if (
      this.emailEdited !==
      this.esignatureEnvelope.esignatureRecipientStatuses[i].email
    ) {
      this.reloadRecipientList();
      if (esigRecipientForm.invalid) {
        return;
      }
      const modalRef: BsModalRef = this.popupMessageModalService.show(
        "Update Customer's Email",
        "Do you want to update this customer's account with this email?",
        this.okButtonCaption,
        this.cancelButtonCaption
      );
      modalRef.content.onClose.subscribe({
        next: (response: string) => {
          if (response === this.okButtonCaption) {
            this.esignatureEnvelope.esignatureRecipientStatuses[
              i
            ].updateEmailInRapport = true;
          }
        },
      });
    }
  }

  private deleteEnvelope(needRedirect: boolean) {
    this.isSubmitting = true;
    this.esignatureEnvelopeService
      .deleteEnvelope(
        this.esignatureEnvelope.applicationId,
        this.esignatureEnvelope.envelopeId
      )
      .subscribe({
        next: (result) => {
          this.isSubmitting = false;
          this.userNotificationSvc.success(
            'Envelope was succesfully deleted.',
            'Success',
            10000,
            this.userNotificationSvc.topRight
          );

          if (needRedirect === true) {
            this.router.navigate([
              '/generate-documents',
              this.esignatureEnvelope.applicationId,
            ]);
          }
          this.close();
        },
        error: (error) => {
          this.userNotificationSvc.error(
            'There was an issue deleting the envelope!',
            'Error',
            10000,
            this.userNotificationSvc.topRight
          );
        },
      });
  }

  enableRecipient(recipient: EsignatureRecipientStatus) {
    if (recipient.email) {
      recipient.emailIsEditable = true;
    }
    if (recipient.phone) {
      recipient.phoneIsEditable = true;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
