<form name="esigRecipientForm" #esigRecipientForm="ngForm">
  <div class="dfs-modal-dialog dfs-modal-content">
    <div class="dfs-modal-header">
      <h3>E-Signature Envelope Status: {{ esignatureEnvelope.status }}</h3>
    </div>
    <div class="dfs-modal-body form-group">
      <h5>Customer: {{ esignatureEnvelope.customerName }}</h5>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Signature Recipient</th>
            <th>Email</th>
            <th>Mobile Phone</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let recipientStatus of esignatureEnvelope.esignatureRecipientStatuses;
              let i = index
            "
            ng-repeat="recipientStatus in ctrl.esignatureRecipientStatuses"
          >
            <td>{{ recipientStatus.recipientName }}</td>
            <td>
              <input
                type="text"
                id="recipientEmail{{ i }}"
                name="recipientEmail{{ i }}"
                [(ngModel)]="recipientStatus.email"
                class="form-control"
                (focus)="setDefaultEmail(i)"
                (blur)="askToUpdateEmail(esigRecipientForm, i)"
                [disabled]="!recipientStatus.emailIsEditable"
              />
            </td>
            <td>
              <p-inputMask
                class="w-100 form-group"
                inputId="recipientPhone{{ i }}"
                name="recipientPhone{{ i }}"
                prevent-phone-match-validator
                [user-number]="currentUserPhone"
                [mobile-number]="recipientStatus.phone"
                [recipient-list]="tempRecipientList"
                [ngClass]="{ 'is-invalid': recipientPhone.invalid }"
                #recipientPhone="ngModel"
                [required]="true"
                placeholder="###-###-####"
                mask="999-999-9999"
                [unmask]="true"
                [(ngModel)]="recipientStatus.phone"
                (onComplete)="askToUpdatePhone(esigRecipientForm, i)"
                [disabled]="!recipientStatus.phoneIsEditable"
              />
              <div
                class="text-danger"
                *ngIf="esigRecipientForm.submitted && recipientPhone.invalid && recipientPhone.errors?.['required']"
              >
                Mobile Number is required
              </div>
              <div
                class="text-danger"
                *ngIf="esigRecipientForm.submitted && recipientPhone.errors?.['mobileMatchesDealer']"
              >
                Please enter a customer phone number. <br />
                Documents can't be sent to DocuSign <br />until the number is
                changed.
              </div>
              <div
                class="text-danger"
                *ngIf="esigRecipientForm.submitted && recipientPhone.errors?.['duplicateMobileFound']"
              >
                Mobile number exists on multiple recipients. <br />
                Documents can't be sent to DocuSign <br />until all numbers are
                unique.
              </div>
            </td>
            <td>{{ recipientStatus.status }}</td>
            <td>
              <button
                class="dfs-btn dfs-btn-primary"
                *ngIf="
                  (recipientStatus.phone || recipientStatus.email) &&
                  recipientStatus.status != 'Completed'
                "
                (click)="enableRecipient(recipientStatus)"
              >
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="dfs-modal-footer col-12" style="text-align: right">
        <div
          *ngIf="esignatureEnvelope.status !== 'Completed'"
          style="display: inline-block"
          ngbDropdown
          #drop="ngbDropdown"
        >
          <p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
          <button
            class="dfs-btn dfs-btn-primary"
            id="actionDropdown"
            ngbDropdownToggle
            [disabled]="isSubmitting"
          >
            <span
              *ngIf="isSubmitting"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Action
          </button>
          <div ngbDropdownMenu aria-labelledby="actionDropdown">
            <button ngbDropdownItem (click)="delete()">Cancel Envelope</button>
            <button ngbDropdownItem (click)="regenerateEnvelope()">
              Re-Generate
            </button>
            <button ngbDropdownItem (click)="resendEnvelope(esigRecipientForm)">
              Re-Send Envelope
            </button>
          </div>
        </div>
        &nbsp;
        <div style="display: inline-block">
          <button class="dfs-btn dfs-btn-primary" (click)="close()">
            Exit
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
