import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { DfsCommonNgxModule } from 'dfs-common-ngx';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import {
  EsignatureModalRecipientService,
  ViewEsignatureModalComponent,
} from './esignature-modal/esignature-modal-recipient.service';
import { FormsModule } from '@angular/forms';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { InputMaskModule } from 'primeng/inputmask';
import { DfsSharedModule } from '../shared/dfs-shared.module';

@NgModule({
  declarations: [DocumentPreviewComponent, ViewEsignatureModalComponent],
  imports: [
    CommonModule,
    InputMaskModule,
    DfsCommonNgxModule.forRoot({ useAuth: true }),
    DfsSharedModule,
    ProgressSpinnerModule,
    RouterModule,
    ButtonsModule.forRoot(),
    FormsModule,
    NgxExtendedPdfViewerModule,
  ],
  providers: [EsignatureModalRecipientService],
})
export class DocumentpreviewModule {}
