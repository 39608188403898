import { SigningLocation } from './signing-location';
import { RecipientRoleType } from './recipient-role-type';

export class Recipient {
  name: string = '';
  email: string = '';
  phone: string = '';
  role: RecipientRoleType = RecipientRoleType.None;
  secondaryRoles: RecipientRoleType[] = [];
  signingLocation!: SigningLocation;
  isCarbonCopyRecipient: boolean = false;
  updatePhoneInRapport: boolean = false;
  updateEmailInRapport: boolean = false;
}
