<Div class="bg-white sticky-top">
  <nav class="py-1 bg-dark">
    <div class="container d-flex">
      <ul class="nav ms-auto">
        <li class="nav-item">
          <div class="d-flex" style="vertical-align: middle">
            <label id="WelcomeLable">{{ welcomeLabel }} </label>
            &nbsp; &nbsp;
            <button
              class="btn btn-outline-light nav-link px-sm-2"
              (click)="logInOut()"
              style="padding: 0"
            >
              {{ buttonText }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <nav class="navbar navbar-expand-lg" aria-label="Main DFS Navigation">
    <div class="container d-flex flex-wrap justify-content-center">
      <a
        [routerLink]="'/home'"
        class="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto"
      >
        <img
          type="image/svg+xml"
          src="assets/svg/dfs-logo-dark.svg"
          alt="Your browser does not support SVGs"
        />
      </a>
      <button
        class="ms-auto navbar-toggler border-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#primaryNav"
        aria-controls="primaryNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="primaryNav">
        <ul
          class="navbar-nav nav ms-auto align-items-end align-items-lg-center"
        >
          <li class="nav-item dropdown text-end text-lg-start">
            <a
              class="nav-link link-dark px-4 dropdown-toggle"
              href="#"
              id="financing"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Financing
            </a>
            <ul
              class="dropdown-menu text-end text-lg-start"
              aria-labelledby="financing"
            >
              <li>
                <a
                  class="dropdown-item"
                  [routerLink]="['/financing', equipmentTypes.farm]"
                  >Equipment Financing</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  [routerLink]="['/financing', equipmentTypes.irrigation]"
                  >Irrigation Financing</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown text-end text-lg-start">
            <a
              class="nav-link link-dark px-4 dropdown-toggle"
              href="#"
              id="about"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >About Us</a
            >
            <ul
              class="dropdown-menu text-end text-lg-start"
              aria-labelledby="about"
            >
              <li>
                <a class="dropdown-item" [routerLink]="['/about']">About DFS</a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/careers']">Careers</a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/contact']"
                  >Contact Us</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown text-end text-lg-start">
            <a
              class="nav-link link-dark px-4 dropdown-toggle"
              href="#"
              id="resources"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >Resources</a
            >
            <ul
              class="dropdown-menu text-end text-lg-start"
              aria-labelledby="resources"
            >
              <li>
                <a class="dropdown-item" [routerLink]="['/applicationforms']"
                  >Application Forms
                </a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="[payOnlineRoute]"
                  >Pay Online</a
                >
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/paymentCalculator']">
                  Loan Payment Calculator
                </a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/faqs']">FAQs</a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  [routerLink]="['/contact']"
                  [queryParams]="{ contacttype: 2 }"
                  >Request Automatic Payment</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  [routerLink]="['/contact']"
                  [queryParams]="{ contacttype: 1 }"
                  >Request Contract Number</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  [href]="['https://www.fnbo.com/scra']"
                  >SCRA</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item text-end text-lg-start">
            <a
              href="assets/forms/EquipmentForSale.pdf"
              target="_blank"
              class="nav-link link-dark px-4"
              >Equipment for Sale</a
            >
          </li>
          <li class="nav-item dropdown text-end text-lg-start">
            <a
              class="nav-link link-dark px-4 dropdown-toggle"
              href="#"
              id="dealers"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >Dealers</a
            >
            <ul
              class="dropdown-menu text-end text-lg-start"
              aria-labelledby="dealers"
            >
              <li>
                <a class="dropdown-item" href="/login">Dealer Login</a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/becomeADealer']"
                  >Become a Dealer Partner</a
                >
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/dealerRegistration']"
                  >Dealer Registration</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</Div>
